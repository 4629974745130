<template>
    <AppLayout title="Welcome">
        <div class="p-12 max-[100vw]">
            <div v-if="errors" class="bg-red-400 w-full my-5 p-4 rounded-xl shadow-lg">
                <p class="font-bold pb-2 last:pb-0" v-for="error in errors">{{ error[0] }}</p>
            </div>

            <CalendarView
                @changeCurrentDay="changeCurrentDay"
                @purchaseBlock="purchaseBlock"
                :spaces="spaces"
                :current-day="currentDay"
                :computed-calendar-view="computedCalendarView"
                :events="events"
                :define-hours="defineHours"
            />
        </div>
    </AppLayout>

    <DialogModal @close="viewSpaceDetails = 0" closeable :show="viewSpaceDetails !== 0">
        <template #title>{{ viewSpaceDetails.name }}</template>
        <template #content>
            <div class="text-lg">Table Size: {{ viewSpaceDetails.size }}</div>
            <div class="mt-4 text-lg">Max Seats: {{ viewSpaceDetails.seats }}</div>
            <div>Description:</div>
            <div class="mt-4" v-html="viewSpaceDetails.description" />
        </template>
        <template #footer>
            <div class="flex gap-4">
                <PrimaryButton @click="viewSpaceDetails = 0">Close</PrimaryButton>
            </div>
        </template>
    </DialogModal>

    <DialogModal @close="closeSelectingDatesModal()" closeable :show="selectingDatesModal">
        <template #title>Make a booking?</template>
        <template #content>
            <div class="mb-2">
                <h3 class="text-md">
                    You are currently booking a table for:
                    <span class="font-bold">{{ selectedSpace.name }}</span>
                </h3>
                <h4 class="text-md">
                    Starting at:
                    <span class="font-bold">{{ formatDateTime(selectedDateTime().toISO()) }}</span>
                </h4>
                <h4 class="text-md">
                    Ending at:
                    <span class="font-bold">{{ formatDateTime(selectedDateTime(amountOfHours).toISO()) }}</span>
                </h4>

                <div class="flex gap-4">
                    <div class="text-md">Table Size: {{ selectedSpace.size }}</div>
                    <div class="text-md">Max Seats: {{ selectedSpace.seats }}</div>
                </div>
            </div>
            <p class="block mb-2 font-medium text-lg text-gray-700">How many hours?</p>
            <div class="flex mb-4 gap-4 items-center">
                <PrimaryButton @click="addHoursToBooking(-1)"><i class="fa-solid fa-minus p-0.5" /></PrimaryButton>
                <div class="text-lg font-extrabold">{{ amountOfHours }}</div>
                <PrimaryButton @click="addHoursToBooking(1)"><i class="fa-solid fa-plus p-0.5" /></PrimaryButton>
            </div>
            <div v-if="calculateBookingPrice()" class="text-2xl font-bold text-right">
                Price: £{{ calculateBookingPrice() }} (Inc. VAT)
            </div>
        </template>
        <template #footer>
            <div class="flex gap-4">
                <DangerButton @click="closeSelectingDatesModal()">Cancel</DangerButton>
                <PrimaryButton v-if="calculateBookingPrice()" @click="checkout()">Book</PrimaryButton>
            </div>
        </template>
    </DialogModal>
</template>

<script setup>
    import AppLayout from "../Layouts/BookingLayouts/AppLayout.vue";
    import PrimaryButton from "../InertiaComponents/PrimaryButton.vue";
    import CalendarView from "../Components/CalendarView.vue";
    import { formatDateTime, getDateFromISO } from "../Helpers/DateHelper.js";
    import DangerButton from "../InertiaComponents/DangerButton.vue";
    import DialogModal from "../InertiaComponents/DialogModal.vue";
    import { DateTime } from "luxon";
    import { computed, ref } from "vue";
    import { router } from "@inertiajs/vue3";

    const props = defineProps({
        canLogin: Boolean,
        canRegister: Boolean,
        laravelVersion: String,
        phpVersion: String,
        auth: Object,
        spaces: Array,
        opening_times: Object,
        orders: Object,
        errors: Object,
        events: Object,
        computedCalendarView: Object,
    });

    const viewSpaceDetails = ref(0);
    const selectedTime = ref(null);
    const selectedSpace = ref(null);
    const selectingDatesModal = ref(false);
    const amountOfHours = ref(1);
    const eventViewing = ref(false);

    const closeSelectingDatesModal = () => {
        selectingDatesModal.value = false;
        selectedTime.value = null;
        selectedSpace.value = null;
        amountOfHours.value = 1;
    };

    const eventSelectToView = computed(() => {
        if (eventViewing.value) {
            return {};
        }

        return null;
    });

    //
    // DAY & HOURS
    //

    const currentDay = ref(DateTime.now());

    const changeCurrentDay = (amount) => {
        currentDay.value = amount;
    };

    const defineHours = computed(() => {
        return props.opening_times[currentDay.value.toFormat("c")].slots;
    });

    const purchaseBlock = ($event, block, space) => {
        if (!props.auth.logged_in) {
            router.get("/login");
        } else {
            selectedTime.value = block;
            selectedSpace.value = space;
            selectingDatesModal.value = true;
            amountOfHours.value = 1;
        }
    };

    const selectedDateTime = (addHours = 0) => {
        return currentDay.value
            .set({ hour: selectedTime.value + addHours })
            .set({ minute: 0 })
            .set({ seconds: 0 });
    };

    const calculateBookingPrice = () => {
        for (let bracket of selectedSpace.value.brackets) {
            if (amountOfHours.value >= bracket.min && amountOfHours.value <= bracket.max) {
                return bracket.price;
            }
        }

        return false;
    };

    const addHoursToBooking = (time) => {
        const tempNewHours = amountOfHours.value + time;
        const lastPossibleTime = getLastPossibleTime();

        if (tempNewHours > 0 && selectedTime.value + tempNewHours <= lastPossibleTime) {
            amountOfHours.value = tempNewHours;
        }
    };

    const getLastPossibleTime = () => {
        const lastPossibleTime = defineHours.value[defineHours.value.length - 1].time + 1;

        for (let event in props.events[selectedSpace.value.id]) {
            const eventStartTime = getDateFromISO(props.events[selectedSpace.value.id][event].date);
            if (eventStartTime.hasSame(currentDay.value, "day")) {
                if (eventStartTime.toFormat("HH") >= selectedTime.value) {
                    return eventStartTime.toFormat("HH");
                }
            }
        }

        for (let order in props.orders[selectedSpace.value.id]) {
            const orderTime = DateTime.fromSQL(order);

            if (orderTime.hasSame(currentDay.value, "day")) {
                if (orderTime.toFormat("HH") >= selectedTime.value) {
                    return orderTime.toFormat("HH");
                }
            }
        }

        return lastPossibleTime;
    };

    const checkout = () => {
        const urlData = {
            startTime: selectedTime.value,
            duration: amountOfHours.value,
            space: selectedSpace.value.id,
            date: selectedDateTime().toISO(),
        };

        window.location.href = "/checkout?" + new URLSearchParams(urlData).toString();
    };
</script>

<style scoped></style>
